
import React from 'react'
import { createHashRouter, Navigate } from 'react-router-dom'
import useToken from '@/utils/useToken.js'

import pageLoader from '../layout/Loader.jsx'
import Layout from '../layout/index.jsx'
import Error404 from '../pages/Error/404.jsx'

const Guide = React.lazy(() => import('../pages/Guide/index.jsx'))
const Entries = React.lazy(() => import('../pages/Entries/index.jsx'))
const Finalists = React.lazy(() => import('../pages/Finalists/index.jsx'))
const Awards = React.lazy(() => import('../pages/Awards/index.jsx'))
const Advances = React.lazy(() => import('../pages/Advances/index.jsx'))
const Signin = React.lazy(() => import('../pages/Auth/Signin.jsx'))
const Signup = React.lazy(() => import('../pages/Auth/Signup.jsx'))

const router = createHashRouter(
  [
    { path: '/auth/signin', Component: Signin, loader: pageLoader },
    { path: '/auth/signup', Component: Signup, loader: pageLoader },
    {
      path: '/', element: <AuthComponent><Layout /></AuthComponent>, loader: pageLoader, errorElement: <Error404 />, children: [
        { index: true, element: <Guide />, loader: pageLoader },
        { path: 'guide', element: <Guide />, loader: pageLoader },
        { path: 'dash', element: <Guide />, loader: pageLoader },
        {
          path: 'entries', element: <Entries />, loader: pageLoader, children: [
            { index: true, element: <Entries />, loader: pageLoader },
            { path: 'list', element: <Entries />, loader: pageLoader }
          ]
        },
        {
          path: 'finalists', element: <Finalists />, loader: pageLoader, children: [
            { index: true, element: <Finalists />, loader: pageLoader },
            { path: 'list', element: <Finalists />, loader: pageLoader }
          ]
        },
        {
          path: 'awards', element: <Awards />, loader: pageLoader, children: [
            { index: true, element: <Awards />, loader: pageLoader },
            { path: 'list', element: <Awards />, loader: pageLoader }
          ]
        },
        {
          path: 'advances', element: <Advances />, loader: pageLoader, children: [
            { index: true, element: <Advances />, loader: pageLoader },
            { path: 'list', element: <Advances />, loader: pageLoader }
          ]
        }
      ]
    }
  ]
)

function AuthComponent({ children }) {
  const { token } = useToken()
  return token ? children : <Navigate to={'/auth/signin'}></Navigate>
}

export default router