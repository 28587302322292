import { useState } from 'react';
import { Settings } from './settings';

const useToken = () => {
    
  const getToken = () => {
    // const token = sessionStorage.getItem(Settings.tk);
    const token = localStorage.getItem(Settings.tk);
    return token;
  };
  
  const [token, setToken] = useState(getToken());


  const saveToken = token => {
    // sessionStorage.setItem(Settings.tk, token);
    localStorage.setItem(Settings.tk, token);
    setToken(token);
  };

  const removeToken = () => {
    // sessionStorage.removeItem(Settings.tk);
    localStorage.removeItem(Settings.tk);
    setToken(null);
    window.alert('令牌已过期，请重新登录');
    window.location.reload();
  };

  return {
    setToken: saveToken,
    token,
    removeToken,
  }

}
export default useToken;