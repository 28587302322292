import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { validLicenseSettings } from './utils/api.js'
import router from './routes/app.js'

import { Enums, Categories } from './utils/settings.js'
import { getEnums, getCategories } from './api/inst.js'

validLicenseSettings()

function App() {
  try {
    getEnums().then((res) => {
      for (let [key, value] of Object.entries(res)) {
        Enums[key] = value
      }
    })
    getCategories({ filter: { type: 'events' }}).then(res => {
      Categories.events = res.list
    })
  } catch (e) {}

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </React.Suspense>
  )
}

export default App
